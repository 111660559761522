//******************************************
//    Change this for ENVIRONMENT !!!!     *
var production:any = true;   //  *
//******************************************
// ENVIRONMENT |  VALUE
//------------------------
//  Production |  true
//------------------------
//  Staging    |  false
//------------------------
//  Localhost  |   0
//------------------------

export const environment = {

  production: production==true?true:false, 
  version: "0.0.0",
  routeUrlJava: production?"https://pristyntech.com/java/":'http://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com/',
  finance_base_url: production?"https://5fruchaoz1.execute-api.ap-south-1.amazonaws.com/production/":'http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/finance/',
  finance_base_upload_url: production?"https://5fruchaoz1.execute-api.ap-south-1.amazonaws.com/production/":'http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/doc_v2/',
  node_url: production?"https://pristyntech.com/":'http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/',
  otpUrl : production ? "https://pristyntech.com/doc/auth/submit-otp" : "http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/bd-dash/auth/submit-otp",
  googleClientId : production ? "173529304992-upggqvh40ohs5dki7sherpouie19hfq0.apps.googleusercontent.com" : "173529304992-mdptju7mpgevb8p547u8njn100pa4bom.apps.googleusercontent.com",
};