import { InfiniteScrollerDirective } from '../../directives/infinite-scroller.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [InfiniteScrollerDirective],
    exports: [InfiniteScrollerDirective]
})
export class InfiniteScrollStatModule {}
