import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ConstantsService } from "../../shared/services/constants.service";
import { HttpServiceService } from "../../shared/services/http-service.service";
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import * as moment from "moment"
import { BehaviorSubject, Subject } from 'rxjs';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForFinalBillService {
url1 :any = "http://localhost:3000/datarows";
url2 :any = "http://localhost:3000/datarows2";
url3:any="http://localhost:3000/tableColumns";
key: string;
count:any;
date:any;

// private apiUrl = 'http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/finance/cityops_dashboard/getPatientViewData';
private responseDataSubject = new Subject<any>();
public setdownloadoption=new BehaviorSubject<any>(null);
public makepopupfalse=new BehaviorSubject<any>(null);
public sharingKey=new BehaviorSubject<any>(null);
  constructor(private http :HttpClient) { }

  // sendPostRequest(data: any): void {
  //   this.http.post(this.apiUrl, data).subscribe((response) => {
  //     // Handle the response as needed
  //     this.responseDataSubject.next(response);
  //   });
  // }

  getResponseData(): Observable<any> {
    return this.responseDataSubject.asObservable();
  }
  final_bill_Admission(){
    let x=this.http.get(this.url1);
    return x;
  }
  final_bill_Discharge(){
    let y=this.http.get(this.url2);
    
    
    return y;
  }
  display_status(){
    let z=this.http.get(this.url3)
    return z;
  }
 

  setKey(key: any) {
    this.key = key;
     }

  getKey(): string {
    return this.key;

  }
  setcount(count:any){
    this.count=count;
  }
  getcount():any{
    return this.count;
  }
  setDate(date:any){
    this.date=date;
  }
  getdate():any{
    return this.date;

  }  
  set_download_option(data){
    this.setdownloadoption.next(data)
  }
  mak_popup_false(data){
    this.makepopupfalse.next(data)
  }
  set_sharing_key(data){
    this.sharingKey.next(data)
   
  }
 
  

}
