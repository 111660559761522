import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core'; 
import { IframePopup } from './../IframePopup';
import { HttpServiceService } from 'src/app/shared/services/http-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
  
@Component({
  selector: 'agreement-view-only',
  templateUrl: './agreement-view.component.html',
  styleUrls: ['./agreement-view.component.scss']
})
export class HospitalChangeComponent implements OnInit{
  @Input() data: any;
  @Output() handleRefreshed: EventEmitter<any> = new EventEmitter();
  hospitalDropdown: any = [];
  currentHospitalId: any = '';
  searchText;
  constructor(public activeModal: NgbActiveModal, 
    public modalService: NgbModal,
    public http: HttpServiceService,
    public toastr: ToastrService,
    public common: CommonService,
    public constants: ConstantsService) {
  }
  ngOnInit() {
    this.getRegionWiseHospitals();
  }
  
  async getRegionWiseHospitals() {
    this.hospitalDropdown = await this.common.returnDropdown(this.data)
  }

  handleHospitalClick(hospital: any = '') {
    this.currentHospitalId = hospital.lable
  }

  close(){
    this.activeModal.close()
    return
  }

  handleSubmit() {
    let reqObj ={
      "appointment_id": this.data.appointment_id,
      "new_hospital_id": this.currentHospitalId
    }
    this.http.postData(this.constants.updateHospitalName, reqObj).then((res: any) => {
        if(!!res.message && res.message.match(/success/gi)) {
            this.toastr.success("Success");
        } else {
          this.toastr.error(!!res.message ? res.message : 'Not able to update the hospital')
        }
        this.handleRefreshed.next();
        this.close()
    }).catch( () => {
        this.toastr.error("Error in the API");
        this.handleRefreshed.next();
        this.close
    })
  }

}
