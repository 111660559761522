import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConstantsService {
  login = environment["routeUrlJava"] + "oauth/token";
  logout = environment["routeUrlJava"] + "api/logout";
  route= environment["routeUrlJava"]+"api/";
  ipdReconcile = environment["routeUrlJava"]+"exposed/";
  ipdRoute = environment["node_url"]+"doc_v2/"
  finance_route = environment["finance_base_url"] + "cityops_dashboard/";
  getInsIpdByAppointmentId = environment["routeUrlJava"] + "api/v2/getGHVDetails"
  //cityOpsUrl = 'http://13.234.225.172/doc/cityops_dashboard/';
  //routeUrl = "http://13.234.225.172/" + "doc/";
  getDischargeSummary = environment["routeUrl"] + "api/v2/getAppointmentFds"
  getpatientPopUpDetails = this.finance_route + "patientPopUpDetails";
  saveBufferData = environment["routeUrlJava"] + "api/v2/saveGHVBuff";
  getHospitalList = this.route + "getHospitalListForGHVDashboard";
  getHospitalDeals = this.route + "getHospitalDeals";
  getInsIpd = this.route + "insIpd";
  getCommonData = this.route +"getcommondata?Name=GHVCalculator";
  addDeal = this.route + "addHospitalDeals";
  getProcedureType = this.route + "getAppointmentProcedureType"
  getGHVShare = this.route + "getGHVShare"
  confirmGHVShare = this.route + "confirmSaveGHVShare";
  uploadDocsFromCentralToIns =  this.ipdRoute + "central-dashboard/uploadDocsFromCentralToIns"
  getDocumentDetailsCentralDash = this.ipdReconcile + "v1/getDocumentDetailsCentralDash"
  updateAppDischargeDate = this.route + "updateAppDischargeDate";
  saveVhcFhcBreakup = this.finance_route + 'saveVhcFhcBreakup';
  updatePatientFinance = this.finance_route + 'updatePatientFinance';
  getNetBillHistory = this.finance_route + 'getNetBillHistory';
  updateHospitalName = this.finance_route + 'updateHospital';
  getRegionWiseHospitals = this.finance_route + 'getHospitalsByRegion';
  addRemarks = environment["finance_base_url"] + "insertRemarks";
  getRemarks = environment["finance_base_url"] + "getRemarks";
  // docServiceUrl = environment["docServiceUrl"]
  updateOpdAmount = this.finance_route + 'updatePatientFinanceOPD';
  bdDashboardRoute = environment["finance_base_upload_url"] + "bd-dashboard/"
  saveImageFormData = this.bdDashboardRoute + "uploadFiles"
  clickToCall = this.bdDashboardRoute + "ClickTOCall";
  getSRNumber = this.bdDashboardRoute + "getSRNumber";
  updateInsDashboard = this.bdDashboardRoute + "updateInsDashboard";


  downloadOpdPatient = this.finance_route + "report/patient/opd";
  downloadOpdHospital = this.finance_route + "report/hospital/opd";
  downloadIpdHospital = this.finance_route + "report/hospital/ipd";
  downloadIpdPatient = this.finance_route + "report/patient/ipd";
  downloadPatientCollection = this.finance_route + "report/patientCollection";
  addPatientPaymentRecord = this.finance_route + "addPatientPayment";
  updatePatientPaymentRecord = this.finance_route + "updatePatientPaymentRecord";
  updateEditableDates = this.finance_route + "updateAppointmentTable";
  addCbBillStatus = this.finance_route + "addCbBillStatus";
  getCategoryDisease = this.route + 'findCategoryAndTreatments';
  generateReport = environment["finance_base_url"] + 'bd-dashboard/forwarding';
  updateGHVFields = this.route + "v2/updateGHVFields";
  signedUrl = environment['node_url'] + 'doc/EMR/getSignedUrl';
  fetchfdsummary= this.finance_route + "fetch-fd-summary"

  //signedUrlEmr = environment['node_url'] + 'finance/EMR/getSignedUrl';
  getReportingData = environment['finance_base_url'] + 'cityops_dashboard/labAndPharmacyReport';
  submitOtp = environment['otpUrl'];
  constructor() {
  }

}