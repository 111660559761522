import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core'; 
import { IframePopup } from './../IframePopup';
  
@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.scss']
})
export class ViewBillComponent implements OnInit{
  @Input() links:{};
  constructor(public activeModal: NgbActiveModal, public modalService: NgbModal) {
  }
  ngOnInit() {
  }
  onViewPdc (url) {
    const modalRef = this.modalService.open(IframePopup, {
      windowClass: "iframe-popup"
    });
    modalRef.componentInstance.data = { url };
    modalRef.result.then(async res => {})
  }
  close(){
    this.activeModal.close()
    return
  }

}
