import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ConstantsService } from "../../../shared/services/constants.service";
import { HttpServiceService } from "../../../shared/services/http-service.service";
// import { CommonService } from "../../../shared/services/common.service";
import {
    NgbModal,
    NgbActiveModal,
    NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-followUp",
    templateUrl: "./clickToCall.component.html",
    styleUrls: ["./clickToCall.component.css"]
})
export class ClickToCallComponent implements OnInit {
    @Input() data;
    cNumber:any;
    @ViewChild("srNumber") srNumberSelect: ElementRef;
    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.fetchSrNumberList();
    }

    fetchSrNumberList() {
        this.http
            .postData(this.constants.getSRNumber, {
                id: localStorage.getItem("userId")
            })
            .then(res => {
                if(res["status"] === 200){
                    let tempArray = res["data"]
                    tempArray = tempArray.map(x=>{
                        return x.SRNumber
                    })
                    this.data.srNumber = tempArray[0]
                }
            });
    }

    selectCusNumber(e){
        let selectedIndex =  e.target.selectedIndex
        this.cNumber = this.data["mobile"][selectedIndex-1] 
    }

    submitFollowUp() {
        let srNumber = this.srNumberSelect.nativeElement.value;
        if (!srNumber) {
            this.toastr.info("Please Select SR Number From The Dropdown");
            return false;
        }
        if (!this.cNumber) {
            this.toastr.info("Please Select Customer Number From The Dropdown");
            return false;
        }
        this.activeModal.close({
            srNumber: '+91'+srNumber,
            cNumber: this.cNumber
        });
    }
}
